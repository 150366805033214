<template>
  <!-- <ui-button raised @click="open = true">Show full-screen dialog</ui-button> -->
  <ui-dialog v-model="open" fullscreen>
    <ui-dialog-title>Отправить вк сообщение пользователю</ui-dialog-title>
    <ui-dialog-content>
      <ui-textfield v-model="messageText" input-type="textarea" rows="18" cols="160">
        Ваше сообщение...
      </ui-textfield>
      <ui-form-field>
        <ui-checkbox v-model="checked" input-id="checkbox"></ui-checkbox>
        <label for="checkbox">Изменить статус номера на ожидает оплату</label>
      </ui-form-field>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button @click="sendVkMesseage" raised>Отправить</ui-button>
    </ui-dialog-actions>
  </ui-dialog>

  <ui-dialog v-model="changeThePrice">
    <div style="display: flex; justify-content: flex-end;">
      <ui-icon-button @click="changeThePrice = false" icon="close"></ui-icon-button>
    </div>
    <ui-dialog-title>Изменить цену у выступления № {{ this.performanceNumber }}</ui-dialog-title>
    <ui-dialog-content>
      <ui-textfield v-model="newPrice">
        Новая цена
      </ui-textfield>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button @click="updatePrice()" raised>Изменить</ui-button>
    </ui-dialog-actions>
  </ui-dialog>

  <ui-dialog v-model="changeTheNote">
    <div style="display: flex; justify-content: flex-end;">
      <ui-icon-button @click="changeTheNote = false" icon="close"></ui-icon-button>
    </div>
    <ui-dialog-title>Добавить заметку для выступления № {{ this.performanceNumber }}</ui-dialog-title>
    <ui-dialog-content>
      <ui-textfield v-model="note">
        Текст
      </ui-textfield>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button @click="addNote()" raised>Добавить</ui-button>
    </ui-dialog-actions>
  </ui-dialog>

  <ui-dialog v-model="changeTheNoteDev">
    <div style="display: flex; justify-content: flex-end;">
      <ui-icon-button @click="changeTheNoteDev = false" icon="close"></ui-icon-button>
    </div>
    <ui-dialog-title>Добавить заметку для разработчика</ui-dialog-title>
    <ui-dialog-content>
      <ui-textfield v-model="noteDev">
        Текст
      </ui-textfield>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button @click="addNoteDev()" raised>Добавить</ui-button>
    </ui-dialog-actions>
  </ui-dialog>

  <ui-dialog v-model="changeTheSchoolFilter" fullscreen>
    <ui-dialog-title>Фильтр по школам:</ui-dialog-title>
    <ui-dialog-content>
      <ui-grid class="demo-grid">
        <ui-grid-cell v-for="item in schools" :key="item" class="demo-cell">
          <ui-form-field>
            <ui-checkbox v-model="checkedNames" :value="item.title"></ui-checkbox>
            <label>{{ item.title }}</label>
          </ui-form-field>
        </ui-grid-cell>
      </ui-grid>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button raised @click="createSchoolFilter">Применить</ui-button>
    </ui-dialog-actions>
  </ui-dialog>

  <ui-dialog v-model="changeTheLevelFilter" fullscreen>
    <ui-dialog-title>Фильтр по уровню подготовки:</ui-dialog-title>
    <ui-dialog-content>
      <ui-grid class="demo-grid">
        <ui-grid-cell v-for="item in levels" :key="item" class="demo-cell">
          <ui-form-field>
            <ui-checkbox v-model="checkedLevels" :value="item"></ui-checkbox>
            <label>{{ item }}</label>
          </ui-form-field>
        </ui-grid-cell>
      </ui-grid>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button raised @click="createLevelFilter">Применить</ui-button>
    </ui-dialog-actions>
  </ui-dialog>

  <ui-dialog v-model="changeTheProjectileFilter" fullscreen>
    <ui-dialog-title>Фильтр по уровню подготовки:</ui-dialog-title>
    <ui-dialog-content>
      <ui-grid class="demo-grid">
        <ui-grid-cell v-for="item in projectiles" :key="item" class="demo-cell">
          <ui-form-field>
            <ui-checkbox v-model="checkedProjectiles" :value="item"></ui-checkbox>
            <label>{{ item }}</label>
          </ui-form-field>
        </ui-grid-cell>
      </ui-grid>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button raised @click="createProjectilesFilter">Применить</ui-button>
    </ui-dialog-actions>
  </ui-dialog>

  <ui-dialog v-model="changeTheStatusFilter" fullscreen>
    <ui-dialog-title>Фильтр по статусам:</ui-dialog-title>
    <ui-dialog-content>
      <ui-grid class="demo-grid">
        <ui-grid-cell v-for="item in statuses" :key="item" class="demo-cell">
          <ui-form-field>
            <ui-checkbox v-model="checkedStatus" :value="item"></ui-checkbox>
            <label>{{ item }}</label>
          </ui-form-field>
        </ui-grid-cell>
      </ui-grid>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button raised @click="createStatusFilter">Применить</ui-button>
    </ui-dialog-actions>
  </ui-dialog>

  <ui-dialog v-model="changeTheSchool">
    <div style="display: flex; justify-content: flex-end;">
      <ui-icon-button @click="changeTheSchool = false" icon="close"></ui-icon-button>
    </div>
    <ui-dialog-title>Изменить школу у выступления № {{ this.performanceNumber }}</ui-dialog-title>
    <ui-dialog-content>
      <h5 v-if="performance">Текущая школа:
        {{ this.performance.school ? this.performance.school.title : 'школа не указана' }}</h5>
      <select v-model="school_selected">
        <option :value="item.id" v-for="item in schools" :key="item">{{ item.title }}</option>
      </select>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button @click="performanceSchoolUpdate()" raised>Изменить</ui-button>
    </ui-dialog-actions>
  </ui-dialog>

  <ui-dialog v-model="changeTheCoach">
    <div style="display: flex; justify-content: flex-end;">
      <ui-icon-button @click="changeTheCoach = false" icon="close"></ui-icon-button>
    </div>
    <ui-dialog-title>Изменить тренера у выступления № {{ this.performanceNumber }}</ui-dialog-title>
    <ui-dialog-content>
      <h5 v-if="performance">Текущий тренер:
        {{
          this.performance.coach ? (this.performance.coach.name + ' ' + this.performance.coach.surname) : 'тренер не указан'
        }}</h5>
      <select v-model="coach_selected">
        <option :value="item.id" v-for="item in coachesArr" :key="item">{{ item.name + ' ' + item.surname }}</option>
      </select>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button @click="performanceCoachUpdate()" raised>Изменить</ui-button>
    </ui-dialog-actions>
  </ui-dialog>

  <ui-dialog v-model="changeTheFIO">

    <div style="display: flex; justify-content: flex-end;">
      <ui-icon-button @click="changeTheFIO = false" icon="close"></ui-icon-button>
    </div>
    <ui-dialog-title>Изменить ФИО пользователя</ui-dialog-title>
    <ui-dialog-content>
      <ui-textfield v-model="user.name">
        Имя
      </ui-textfield>
      <br/>
      <ui-textfield v-model="user.surname">
        Фамилия
      </ui-textfield>
      <br/>
      <ui-textfield v-model="user.lastname">
        Отчество
      </ui-textfield>
    </ui-dialog-content>
    <ui-dialog-actions>
      <ui-button @click="userUpdate()" raised>Изменить</ui-button>
    </ui-dialog-actions>
  </ui-dialog>


  <ui-switch v-model="toggle1" input-id="basic-switch">
    {{ toggle1 }}
  </ui-switch>

  <h3>{{ toggle1 ? 'Сокращенная таблица' : 'Вся таблица' }} | Количество зарегистрированных выступлений: {{
      performanceArr.length
    }} </h3>

  <h4 style="display: inline-block">Смена города выступления </h4>
  <div style="display: inline-block">
    <ui-button @click="toggleFilter()">
      <span v-if="filterCity === 2">Выберите город</span>
      <span v-else-if="filterCity === true">Сочи</span>
      <span v-else>Питер</span>
    </ui-button>
  </div>
  <!-- <hr> -->
  <div
      v-if="checkedNames.length > 0 || checkedStatus.length > 0 || checkedLevels.length > 0 || checkedProjectiles.length > 0">
    <h4>Фильтры:</h4>
    <div class="filter-container">
      <ui-grid class="demo-grid">
        <ui-grid-cell v-for="(item, index) in checkedNames" :key="item" class="demo-cell">
          <div style="display: flex;">
            <ui-button raised>{{ item }}</ui-button>
            <ui-icon-button @click="this.checkedNames.splice(index, 1)" icon="close"></ui-icon-button>
          </div>
        </ui-grid-cell>
      </ui-grid>
      <ui-grid class="demo-grid">
        <ui-grid-cell v-for="(item, index) in checkedLevels" :key="item" class="demo-cell">
          <div style="display: flex;">
            <ui-button raised>{{ item }}</ui-button>
            <ui-icon-button @click="this.checkedLevels.splice(index, 1)" icon="close"></ui-icon-button>
          </div>
        </ui-grid-cell>
      </ui-grid>
      <ui-grid class="demo-grid">
        <ui-grid-cell v-for="(item, index) in checkedProjectiles" :key="item" class="demo-cell">
          <div style="display: flex;">
            <ui-button raised>{{ item }}</ui-button>
            <ui-icon-button @click="this.checkedProjectiles.splice(index, 1)" icon="close"></ui-icon-button>
          </div>
        </ui-grid-cell>
      </ui-grid>
      <ui-grid class="demo-grid">
        <ui-grid-cell v-for="(item, index) in checkedStatus" :key="item" class="demo-cell">
          <div style="display: flex;">
            <ui-button raised>{{ item }}</ui-button>
            <ui-icon-button @click="this.checkedStatus.splice(index, 1)" icon="close"></ui-icon-button>
          </div>
        </ui-grid-cell>
        <button @click="clearFilter" style="width: 200px;">Очистить фильтр</button>
      </ui-grid>
    </div>
    <!-- <ui-icon-button @click="checkedNames = []" icon="close"></ui-icon-button> -->
  </div>
  <div class="table-wrapper">
    <table>
      <thead>
      <tr>
        <!-- <th>id</th> -->
        <th>ID</th>
        <th class="sticky-column">ФИО</th>
        <th v-if="!toggle1">Дата рождения</th>
        <th class="sticky-column" style="left:5.5%;" data-tooltip-id="tooltip-demo-2">Возраст</th>
        <th>Возрастная категория</th>
        <th v-if="!toggle1">Город</th>
        <th @click="openFilterForLevel" class="setting_td-block"><img src="../../public/filter.svg" alt=""> Уровень <br>
          подготовки
        </th>
        <th>Номинация</th>
        <th @click="openFilterForProjectiles" class="setting_td-block"><img src="../../public/filter.svg" alt="">Снаряд
        </th>
        <th>Соло/Дуэт</th>
        <th>Данные <br> партера по дуэту</th>
        <th v-if="!toggle1">Командное <br> участие</th>
        <th @click="openFilterForSchool" class="setting_td-block"><img src="../../public/filter.svg" alt=""> Название
          <br> студии/школы
        </th>
        <th>Тренер</th>
        <th v-if="!toggle1">Сопровождающий</th>
        <th v-if="!toggle1">Email</th>
        <th v-if="!toggle1">Телефон</th>
        <th>Цена</th>
        <th>Комментарий для организаторов</th>
        <th>Текст для ведущего</th>
        <th>Заметки</th>
        <th @click="changeTheStatusFilter = true" class="setting_td-block"><img src="../../public/filter.svg" alt="">
          Статус
        </th>
        <th>
          <a v-if="filterCity === 2" href="https://app.inform-s.ru/api/performance-export">
            <ui-fab extended>Выгрузка в EXCEL</ui-fab>
          </a>
          <a v-else-if="filterCity" href="https://app.inform-s.ru/api/performance-export-sochi">
            <ui-fab extended style="background-color: #008000">Выгрузка в EXCEL Сочи</ui-fab>
          </a>
          <a v-else href="https://app.inform-s.ru/api/performance-export-spb">
            <ui-fab extended style="background-color: #FFC72C">Выгрузка в EXCEL Питер</ui-fab>
          </a>
        </th>
        <th>
          <a href="#" v-on:click.prevent="downloadArchive" v-if="queueSize">
            <ui-fab extended>Скачать музыку</ui-fab>
          </a>

          <a href="https://app.inform-s.ru/api/upload-archive?fileName=archive.zip" v-else>
            <ui-fab extended style="background-color: red">Загрузить архив</ui-fab>
          </a>

          <ui-fab extended @click="disableAccessAudios(performance)" hidden="hidden">
            Заблокировать загрузку музыки
          </ui-fab>

        </th>
        <th>
          Комментарии для разработчиков
        </th>
      </tr>
      </thead>
      <tbody>
      <tr @click="initPerformance(index + 1, performance)" v-for="(performance, index) in returnFilterArr"
          :key="performance.id">
        <td>{{ index + 1 }}</td>

        <td v-if="toggle1" class="sticky-column setting_td-block"
            @click="changeTheFIO = true"
            :class="{ 'updated_level': performanceUpdated.filter(
                item => item.performance_id === performance.id &&
                item.new_name === 1
                ).length > 0 }"
            @mouseover="sendPostRequestName(performance.id)"
        >
          {{ shortFIO(performance.user) }}
          <span v-if="performance.patner_fio !== 'не указан'">
            {{
              (performance.patner_fio ?? '').trim().replace(/\s+/g, ' ').split(' ')[0]
            }} {{
              (performance.patner_fio ?? '').trim().replace(/\s+/g, ' ').split(' ').slice(1).map(word => word[0] + '.').join(' ')
            }}
          </span>
        </td>

        <td v-if="!toggle1" class="sticky-column setting_td-block"
            @click="changeTheFIO = true"
            :class="{ 'updated_level': performanceUpdated.filter(
                item => item.performance_id === performance.id &&
                 item.new_name === 1
                ).length > 0 }"
            @mouseover="sendPostRequestName(performance.id)"
        >
          {{ performance.user.surname }} {{ performance.user.name }} {{ performance.user.patronymic }}
        </td>

        <td v-if="!toggle1"
            :class="{ 'updated_level': performanceUpdated.filter(
                item => item.performance_id === performance.id &&
                item.new_date_of_birth === 1
                ).length > 0 }"
            @mouseover="sendPostRequestBirth(performance.id)"
        >
            {{ reverseDate(performance.user.date_of_birth) }}
        </td>

        <td class="sticky-column" style="left:5.5%;"
            :class="{ 'updated_level': performanceUpdated.filter(
                item => item.performance_id === performance.id &&
                item.new_date_of_birth === 1
                ).length > 0 }"
            @mouseover="sendPostRequestBirth(performance.id)"
        >
          {{ getUserYears(performance.user.date_of_birth) ?? ''}} лет
        </td>

        <td> {{ performance.age_category }}</td>
        <td v-if="!toggle1">{{ performance.user.city }}</td>

        <td
            :class="{ 'updated_level': performanceUpdated.filter(
                item => item.performance_id === performance.id &&
                item.new_level === 1
                ).length > 0 }"
            @mouseover="sendPostRequest(performance.id)"
        >
          {{ performance.level }}
        </td>

        <td
            :class="{ 'updated_level': performanceUpdated.filter(
                item => item.performance_id === performance.id &&
                item.new_nomination === 1
                ).length > 0 }"
            @mouseover="sendPostRequestNomination(performance.id)"
        >
            <select v-model="performance.nomination" @change="sendRequest">
                <option disabled value="">Выберите номинацию</option>
                <option v-for="nomination in nominations" :key="nomination" :value="nomination">
                    {{ nomination }}
                </option>
            </select>
        </td>

        <td
            :class="{ 'updated_level': performanceUpdated.filter(
                item => item.performance_id === performance.id &&
                item.new_projectile === 1
                ).length > 0 }"
            @mouseover="sendPostRequestProjectile(performance.id)"
        >
          {{ performance.projectile.title }}
        </td>

        <td>{{ performance.solo_or_duet }}</td>

        <td v-if="performance.patner_fio !== '0'"
            :class="{ 'updated_level': performanceUpdated.filter(
                item => item.performance_id === performance.id &&
                item.new_patner_fio === 1
                ).length > 0 }"
            @mouseover="sendPostRequestPatner(performance.id)"
        >
          {{ performance.patner_fio }}
        </td>

        <td v-if="performance.patner_fio === '0'">соло</td>

        <template v-if="!toggle1">
          <td v-if="performance.team">Командное участие</td>
          <td v-if="!performance.team">Участие без команды</td>
        </template>

        <td @click="openChangeTheSchool" class="setting_td-block"
            v-if="performance.school && !performance.custom_school && !performance.index_school">{{
            performance.school.title
          }}
        </td>

        <td @click="openChangeTheSchool" class="setting_td-block" v-if="performance.index_school">{{
            performance.school.title
          }} <span style="color: rgb(85, 85, 85);">({{
              performance.index_school.title
            }})</span></td>

        <td @click="openChangeTheSchool" class="setting_td-block" v-if="performance.custom_school"
            :class="{ 'updated_level': performanceUpdated.filter(
                item => item.performance_id === performance.id &&
                item.new_team === 1
                ).length > 0 }"
            @mouseover="sendPostRequestTeam(performance.id)"
        >
          {{ performance.custom_school.title }}
        </td>

        <td @click="openChangeTheSchool" class="setting_td-block"
            v-if="!performance.school && !performance.custom_school">Школа не указана
        </td>

        <td @click="changeTheCoach = true" class="setting_td-block" v-if="!toggle1 && performance.coach">
          {{ performance.coach.surname }} {{
            performance.coach.name
          }}
        </td>

        <td @click="changeTheCoach = true" class="setting_td-block" v-if="!toggle1 && performance.custom_coach"
            :class="{ 'updated_level': performanceUpdated.filter(
                item => item.performance_id === performance.id &&
                item.new_coach === 1
                ).length > 0 }"
            @mouseover="sendPostRequestCoach(performance.id)"
        >
          {{ performance.custom_coach }}
        </td>

        <td @click="changeTheCoach = true" class="setting_td-block" v-if="toggle1 && performance.custom_coach"
            :class="{ 'updated_level': performanceUpdated.filter(
                item => item.performance_id === performance.id &&
                item.new_coach === 1
                ).length > 0 }"
            @mouseover="sendPostRequestCoach(performance.id)"
        >{{ shortCustomFIO(performance.custom_coach) }}
        </td>

        <td @click="changeTheCoach = true" class="setting_td-block" v-if="toggle1 && performance.coach">
          {{ shortFIO(performance.coach) }}{{performance.custom_coach_id}}
        </td>

        <td @click="changeTheCoach = true" class="setting_td-block"
            v-if="!performance.coach"
            :class="{ 'updated_level': performanceUpdated.filter(
                item => item.performance_id === performance.id &&
                item.new_coach === 1
                ).length > 0 }"
            @mouseover="sendPostRequestCoach(performance.id)"
        >
            {{ performance.custom_coache_title === "" ? (performance.custom_coache ? performance.custom_coache.title : '') : performance.custom_coache_title }}
        </td>

        <template v-if="!toggle1">
          <td v-if="performance.user.accompanying">{{ performance.user.accompanying.fio }}</td>
          <td v-if="!performance.user.accompanying">не указан</td>
        </template>

        <td v-if="!toggle1">{{ performance.user.email }}</td>
        <td v-if="!toggle1">{{ performance.user.phone }}</td>
        <td @click="setThePrice" class="setting_td-block">{{ performance.price }} ₽</td>
        <td>{{ performance.comment ? performance.comment : 'не указан' }}</td>
        <td>{{ performance.leader_text ? performance.leader_text : 'не указан' }}</td>
        <td @click="changeTheNote = true" class="setting_td-block">{{ performance?.note }}</td>

        <performanceStatus :status="performance.status"/>

        <td>
          <!-- <a class="action" href="#" v-if="performance.status === 1">Изменить</a>
          <br /> -->
          <!-- <a class="action" @click="deletePerformance(performance)" href="#">Удалить</a> -->
          <a style="color: #00bfff; cursor: pointer;" @click="getPerformance(performance)"
             class="action">
            <ui-icon>send</ui-icon>
            Отправить сообщение</a>
          <br/>
          <a style="color: #008000; cursor: pointer;" @click="acceptPerformanceModal(performance)"
             class="action">
            <ui-icon>done</ui-icon>
            Подтвердить</a>
          <br/>
          <a style="color: #FFB02E; cursor: pointer;" @click="awaitPerformanceModal(performance)"
             class="action">
            <ui-icon>access_time</ui-icon>
            Ожидаем оплаты</a>
          <br/>
          <a style="color: #008000; cursor: pointer;" @click="paidPerformanceModal(performance)"
             class="action">
            <ui-icon>credit_score</ui-icon>
            Оплачено</a>
          <br/>
          <a style="color: #ff0000; cursor: pointer;" @click="rejectPerformanceModal(performance)"
             class="action">
            <ui-icon>block</ui-icon>
            Отклонить</a>
          <br/>
          <a style="color: #ff0000; cursor: pointer;" @click="deletePerformanceModal(performance)"
             class="action">
            <ui-icon>delete_forever</ui-icon>
            Удалить</a>
        </td>


        <confirmAudio :performance="performance"></confirmAudio>
        <!--        {{performanceArr}}-->
        <td @click="changeTheNoteDev = true" class="setting_td-block">
          {{ performance?.note_dev }}
        </td>

      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'
import performanceStatus from '@/components/PerformanceStatus.vue'
import confirmAudio from "@/components/ConfirmAudio.vue";
import AwaitZipModal from "@/components/modals/AwaitZipModal.vue";
/* eslint-disable */
const user = {
  id: "",
  name: "",
  surname: "",
  lastname: "",
}

const statuses = [
  'Оплачен',
  'Ожидает оплаты',
  'Подтверждён',
  'Отклонён',
  'Ожидание'
];

const levels = [
  'Дебют',
  'Начинающие',
  'Продвинутые',
  'Профессионалы',
  'Элита',
]

const projectiles = [
  'Пилон',
  'Полотно',
  'Кольцо',
  'Стропы',
  'Петля(гамак)',
  'Оригинальный снаряд',
  'Бортик',
]

const nominations = ['Арт', 'Спорт', 'Exotic pole'];
export default {
  components: {AwaitZipModal, performanceStatus, confirmAudio},
  data() {
    return {
      user,
      statuses,
      levels,
      projectiles,
      nominations,
      checkedNames: [],
      checkedStatus: [],
      checkedProjectiles: [],
      checkedLevels: [],
      checkedPerformanceCity: [],
      school_selected: '',
      coach_selected: '',
      changeTheSchoolFilter: false,
      changeTheLevelFilter: false,
      changeTheProjectilesFilter: false,
      changeTheProjectileFilter: false,
      changeTheStatusFilter: false,
      changeTheSchool: false,
      changeTheCoach: false,
      changeThePrice: false,
      changeTheNote: false,
      changeTheNoteDev: false,
      changeTheFIO: false,
      toggle1: true,
      performanceNumber: false,
      performance: null,
      performance_id: null,
      performanceArr: [],
      checked: false,
      coachesArr: [],
      open: false,
      fixedFIO: false,
      fixedScrollClass: 'fixed-scroll',
      vkUserid: null,
      newPrice: '',
      messageText: '',
      note: '',
      noteDev: '',
      schools: [],
      audioConfirm: false,
      audioAccess: true,
      queueSize: 1,
      filterCity: 2,
      data: [],
      performanceUpdated: [],
    }
  },
  created() {
    this.checkToken()
    this.performanceUpdate()
    this.performances()
    this.coaches()
  },
  computed: {

    returnFilterArr() {
      if (this.checkedNames.length > 0
          || this.checkedStatus.length > 0
          || this.checkedLevels.length > 0
          || this.checkedProjectiles.length > 0) {
        return this.performanceArr.filter(elem => {
          const {school} = elem;
          return school && this.checkedNames.includes(school.title)
              || elem && this.checkedStatus.includes(elem.status)
              || elem && this.checkedLevels.includes(elem.level)
              || elem && this.checkedProjectiles.includes(elem.projectile.title)
        });
      }
      return this.performanceArr;
    },

  },
  methods: {
    sendRequest() {
        // Assuming you want to send a POST request with the selected nomination
        axios.post(`${process.env.VUE_APP_DOMAIN}/api/change-nomination`, {
            nomination: this.performance.nomination,
            id: this.performance.id
        })
            .then(response => {
                // Handle the response from the server
                console.log(response.data);
            })
            .catch(error => {
                // Handle any errors
                console.error('Error sending request:', error);
            });
    },
    sendPostRequestCoach(id) {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/disable-updates`, {
        id: id,
        new_coach: false
      })
          .then(response => {
            console.log(response);
              setTimeout(() => {
                  this.performanceUpdate()
              }, 2000)
          })
          .catch(error => {
            setTimeout(() => {
              this.performanceUpdate()
            }, 2000)
            console.error(error);

          });
    },
    sendPostRequestTeam(id) {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/disable-updates`, {
        id: id,
        new_team: false
      })
          .then(response => {
            console.log(response);
              setTimeout(() => {
                  this.performanceUpdate()
              }, 2000)
          })
          .catch(error => {
            setTimeout(() => {
              this.performanceUpdate()
            }, 2000)
            console.error(error);

          });
    },
    sendPostRequestPatner(id) {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/disable-updates`, {
        id: id,
        new_patner_fio: false
      })
          .then(response => {
            console.log(response);
              setTimeout(() => {
                  this.performanceUpdate()
              }, 2000)
          })
          .catch(error => {
            setTimeout(() => {
              this.performanceUpdate()
            }, 2000)
            console.error(error);

          });
    },
    sendPostRequestBirth(id) {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/disable-updates`, {
        id: id,
        new_date_of_birth: false
      })
          .then(response => {
            console.log(response);
              setTimeout(() => {
                  this.performanceUpdate()
              }, 2000)
          })
          .catch(error => {
            setTimeout(() => {
              this.performanceUpdate()
            }, 2000)
            console.error(error);

          });
    },
    sendPostRequestName(id) {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/disable-updates`, {
        id: id,
        new_name: false
      })
          .then(response => {
            console.log(response);
              setTimeout(() => {
                  this.performanceUpdate()
              }, 2000)
          })
          .catch(error => {
            setTimeout(() => {
              this.performanceUpdate()
            }, 2000)
            console.error(error);

          });
    },
    sendPostRequest(id) {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/disable-updates`, {
        id: id,
        new_level: false
      })
          .then(response => {
            console.log(response);
              setTimeout(() => {
                  this.performanceUpdate()
              }, 2000)
          })
          .catch(error => {
            setTimeout(() => {
              this.performanceUpdate()
            }, 2000)
            console.error(error);

          });
    },
    sendPostRequestProjectile(id) {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/disable-updates`, {
        id: id,
        new_projectile: false
      })
          .then(response => {
            console.log(response);
              setTimeout(() => {
                  this.performanceUpdate()
              }, 2000)
          })
          .catch(error => {
            setTimeout(() => {
              this.performanceUpdate()
            }, 2000)
            console.error(error);
          });
    },
    sendPostRequestNomination(id) {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/disable-updates`, {
        id: id,
        new_nomination: false
      })
          .then(response => {
            console.log(response);
              setTimeout(() => {
                  this.performanceUpdate()
              }, 2000)
          })
          .catch(error => {
            setTimeout(() => {
              this.performanceUpdate()
            }, 2000)
            console.error(error);

          });
    },
    toggleFilter() {
      if (this.filterCity === true) {
        this.filterCity = false;
      } else {
        this.filterCity = true;
      }
      this.filterData();
    },
    filterData() {
      if (this.filterCity === true) {
        this.performanceArr = this.data.filter(item => item.performance_city_id === 1);
      } else if (this.filterCity === false) {
        this.performanceArr = this.data.filter(item => item.performance_city_id === 2);
      }
    },
    async downloadArchive() {
      try {
        // Отправляем запрос на создание архива
        await axios.get(`${process.env.VUE_APP_DOMAIN}/api/zip/audios`);
        alert("Подождите пока архив загрузится, кнопка станет красной");
        // Ждем, пока очередь станет пустой
        while (true) {
          const response = await axios.get(`${process.env.VUE_APP_DOMAIN}/api/queue/size`);

          if (response.data.size === 0) {
            this.queueSize = 0;
            break;
          } else {
            // Ждем 1 секунду перед следующей проверкой
            await new Promise(resolve => setTimeout(resolve, 2000));
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    },
    accessAudios() {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/access-audios`,)
          .then(() => {
            this.audioAccess = true;
            axios.post(`${process.env.VUE_APP_DOMAIN}/api/update-audio-access`, {
              'performance_id': this.performance.id,
              'audio_access': this.audioAccess,
            })
                .then(() => {
                  console.log("Значение audioConfirm успешно обновлено в базе данных");
                  this.$forceUpdate();
                })
                .catch((err) => {
                  console.log(err);
                });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    disableAccessAudios() {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/disable-access-audios`,)
          .then(() => {
            this.audioAccess = false;
            axios.post(`${process.env.VUE_APP_DOMAIN}/api/update-audio-access`, {
              'performance_id': this.performance.id,
              'audio_access': this.audioAccess,
            })
                .then(() => {
                  console.log("Значение audioConfirm успешно обновлено в базе данных");
                  this.$forceUpdate();
                })
                .catch((err) => {
                  console.log(err);
                });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    createSchoolFilter() {
      this.changeTheSchoolFilter = false
    },
    createLevelFilter() {
      this.changeTheLevelFilter = false
    },

    createProjectilesFilter() {
      this.changeTheProjectileFilter = false
    },

    createStatusFilter() {
      this.changeTheStatusFilter = false
    },
    openChangeTheSchool() {
      this.fetchSchools()
      this.changeTheSchool = true
    },
    openFilterForSchool() {
      this.fetchSchools()
      this.changeTheSchoolFilter = true
    },
    openFilterForLevel() {
      // this.fetchLevels()
      this.changeTheLevelFilter = true
    },
    openFilterForProjectiles() {
      // this.fetchLevels()
      this.changeTheProjectileFilter = true
    },
    coaches() {
      axios.get(`${process.env.VUE_APP_DOMAIN}/api/coaches`)
          .then((res) => {
            this.coachesArr = res.data
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    fetchSchools() {
      axios
          .get(`${process.env.VUE_APP_DOMAIN}/api/schools`)
          .then((res) => {
            this.schools = res.data.schools;
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
    },
    fetchLevels() {
      axios
          .get(`${process.env.VUE_APP_DOMAIN}/api/level`)
          .then((res) => {
            this.levels = res.data.level;
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
    },
    initPerformance(number, performance) {
      this.user.id = performance.user.id
      this.user.name = performance.user.name
      this.user.surname = performance.user.surname
      this.user.lastname = performance.user.patronymic
      this.performance = performance
      this.performanceNumber = number
    },
    userUpdate() {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/user/update`, {
        id: this.user.id,
        surname: this.user.surname,
        name: this.user.name,
        lastname: this.user.lastname
      })
          .then((res) => {
            this.performances()
            this.changeTheFIO = false
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    setThePrice() {
      this.changeThePrice = true
    },
    updatePrice() {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/performance-update`, {
        performance_id: this.performance.id,
        type: this.performance.type,
        changed: 'отредактирован администратором',
        price: this.newPrice,
      })
          .then((res) => {
            this.performances()
            this.newPrice = ''
            this.changeThePrice = false
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          })
    },
    paidPerformanceModal(performance) {
      this.$confirm({
        message: 'Вы точно хотите это изменить статус на оплаченно ?',
        state: 'help',
        acceptText: 'Да',
        cancelText: 'Нет'
      }).then((result) => {
        if (result) {
          this.paidPerformance(performance)
          console.log(performance)
        }
      });
    },
    clearFilter() {
      this.checkedNames = []
      this.checkedStatus = []
    },
    acceptPerformanceModal(performance) {
      this.$confirm({
        message: 'Вы точно хотите это подтвердить это выступление ?',
        state: 'help',
        acceptText: 'Да',
        cancelText: 'Нет'
      }).then((result) => {
        if (result) {
          this.acceptPerformance(performance)
          console.log(performance)
        }
      });
    },
    rejectPerformanceModal(performance) {
      this.$confirm({
        message: 'Вы точно хотите отклонить это выступление ?',
        state: 'help',
        acceptText: 'Да',
        cancelText: 'Нет'
      }).then((result) => {
        if (result) {
          this.rejectPerformance(performance)
          console.log(performance)
        }
      });
    },
    performanceSchoolUpdate() {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/performance/update/school`, {
        performance_id: this.performance.id,
        school_id: this.school_selected,
      })
          .then((res) => {
            this.changeTheSchool = false
            this.performances()
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    performanceCoachUpdate() {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/performance/update/coach`, {
        performance_id: this.performance.id,
        coach_id: this.coach_selected,
      })
          .then((res) => {
            this.changeTheCoach = false
            this.performances()
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    deletePerformanceModal(performance) {
      this.$confirm({
        message: 'Вы точно хотите удалить это выступление ?',
        state: 'help',
        acceptText: 'Да',
        cancelText: 'Нет'
      }).then((result) => {
        if (result) {
          this.deletePerformance(performance)
          this.performances()
          console.log(performance)
        }
      });
    },
    awaitPerformanceModal(performance) {
      this.$confirm({
        message: 'Вы точно хотите напомнить об оплате ?',
        state: 'help',
        acceptText: 'Да',
        cancelText: 'Нет'
      }).then((result) => {
        if (result) {
          this.awaitPerformance(performance)
          this.performance.status = "Ожидает оплаты"
          console.log(performance)
        }
      });
    },
    checkToken() {
      const config = {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
      };

      const url = `${process.env.VUE_APP_DOMAIN}/api/check-token`;

      const data = {}

      axios.post(url, data, config)
          .then(res => console.log(res))
          .catch((err) => {
            this.$store.state.token = null
            this.$alert('Вы не авторизованны')
            localStorage.removeItem('token')
            this.$router.push('/')
            console.log(err)
          })
    },
    getPerformance(performance) {
      console.log(performance)
      this.checked = false
      this.open = true
      this.performance = performance
    },
    sendVkMesseage() {
      this.open = false
      const config = {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
      };

      if (this.checked) {
        this.awaitPerformance(this.performance)
      }

      const url = `${process.env.VUE_APP_DOMAIN}/api/message`;

      let city = '';
      if (this.performance.performance_city_id === 1) {
        city = 'Сочи'
      } else {
        city = 'Санкт-Петербург'
      }

      let text = `
        Участник: ${this.performance.user.surname} ${this.performance.user.name} ${this.performance.user.patronymic}
        Город выступления: ${city}
        Снаряд: ${this.performance.projectile.title}
        ${this.messageText}`;

      const data = {
        vkUserid: this.performance.vk_user.vk_id,
        text: text,
      }

      axios.post(url, data, config)
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    findDuplicates(arr) {
      const duplicates = [];
      arr.forEach((obj, i) => {
        console.log(obj)
        arr.slice(i + 1).forEach((otherObj) => {
          if (this.isEqual(obj, otherObj)) {
            if (!duplicates.includes(obj)) {
              duplicates.push(obj);
            }
            if (!duplicates.includes(otherObj)) {
              duplicates.push(otherObj);
            }
          }
        });
      });
      console.log(duplicates)
      return duplicates;
    },
    isEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2);
    },
    shortFIO(user) {
      if (!user) {
        return '';
      }

      let fio = '';

      if (user.surname) {
        fio += user.surname;
      }

      if (user.name) {
        fio += ' ' + user.name.charAt(0) + '.';
      }

      if (user.patronymic) {
        fio += ' ' + user.patronymic.charAt(0) + '.';
      }

      return fio.trim();
    },
    getUserYears(date) {
      const birthDate = new Date(date);

      const diff = Date.now() - birthDate.getTime();

      const age = new Date(diff).getFullYear() - 1970;

      return age;
    },
    performanceUpdate() {
      axios.get(`${process.env.VUE_APP_DOMAIN}/api/performance-updated`)
          .then((res) => {
            this.performanceUpdated = res.data
          })
    },
    async performances() {
      const url = `${process.env.VUE_APP_DOMAIN}/api/performances`;

      const config = {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
      };
      this.performanceArr = this.performanceUpdated
      await axios.get(url, config)
          .then((res) => {
            console.log(res)
            this.data = res.data
            this.performanceArr = res.data

          })
          .catch((err) => {
            console.log(err)
          })
    },
    openAcceptPerformanceModal(performance) {
      this.acceptPerformanceModalFlag = true
      this.performance = performance
    },
    async acceptPerformance(performance) {
      const config = {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
      };

      const url = `${process.env.VUE_APP_DOMAIN}/api/accept-performance`;

      const data = {
        performance_id: performance.id
      }


      await axios.post(url, data, config)
          .then((res) => {
            // this.confirmedPrice(performance.id, performance.price)
            let vk_user_id = res.data
            this.createVkMessage(vk_user_id, performance)
            this.performance.status = "Подтверждён"
          })
          .catch((err) => {
            console.log(err)
          })
    },
    async paidPerformance(performance) {

      const config = {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
      };

      const url = `${process.env.VUE_APP_DOMAIN}/api/paid-performance`;

      const data = {
        performance_id: performance.id
      }

      await axios.post(url, data, config)
          .then((res) => {
            let vk_user_id = res.data
            this.createPaidVkMessage(vk_user_id, performance)
            this.performance.status = "Оплачен"
          })
          .catch((err) => {
            console.log(err)
          })
    },
    addNote() {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/performance/update/note`, {
        'performance_id': this.performance.id,
        'note': this.note
      })
          .then((res) => {
            this.performances()
            this.changeTheNote = false
            this.note = ''
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    reverseDate(date) {
      // Преобразование даты в формат dd-mm-yyyy
      let dateParts = date.split("-");
      return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    },
    addNoteDev() {
      axios.post(`${process.env.VUE_APP_DOMAIN}/api/note-dev`, {
        'performance_id': this.performance.id,
        'note_dev': this.noteDev
      })
          .then((res) => {
            this.performances()
            this.changeTheNote = false
            this.noteDev = ''
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    async awaitPerformance(performance) {
      const config = {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
      };

      const url = `${process.env.VUE_APP_DOMAIN}/api/await-performance`;

      const data = {
        performance_id: performance.id
      }

      axios.post(url, data, config)
          .then((res) => {
            console.log(res)
            // let vk_user_id = res.data
            // this.createPaidVkMessage(vk_user_id, performance)
            // this.$alert('поле обновлено')
            performance.status = 'Ожидает оплаты'
            this.$forceUpdate();
          })
          .catch((err) => {
            console.log(err)
          })
    },

    async rejectPerformance(performance) {

      const config = {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
      };

      const url = `${process.env.VUE_APP_DOMAIN}/api/reject-performance`;

      const data = {
        performance_id: performance.id
      }

      await axios.post(url, data, config)
          .then((res) => {
            let vk_user_id = res.data
            this.createVkRejectMessage(vk_user_id, performance)
            this.performance.status = "Отклонён"
          })
          .catch((err) => {
            console.log(err)
          })
    },
    async createVkMessage(vk_user_id, performance) {

      let city = '';
      if (this.performance.performance_city_id === 1) {
        city = 'Сочи'
      } else {
        city = 'Санкт-Петербург'
      }
      let message = `✅ Подтверждение участия:
               |перенос| ${performance.vk_user.name}, выступление подтвержденно!
               |перенос| Участник:  ${performance.user.surname} ${performance.user.name} ${performance.user.patronymic}   
               |перенос| Город:  ${city}
               |перенос| Снаряд: ${performance.projectile.title}
               |перенос| Номинация: ${performance.nomination}    
               |перенос| Тип:  ${performance.solo_or_duet}
               |перенос| Стоимость:  ${performance.price}
               |перенос| Форма оплаты взносов находится по ссылке https://taplink.cc/malinovoe.nebo.fest/p/98f10c/
               |перенос| до 20 марта включительно ( для Сочи)
               |перенос| до до 5 апреля включительно ( для Питера)
               |перенос| Ваш платеж мы видим автоматически, чек об оплате присылать не требуется.`

      await axios.get(`${process.env.VUE_APP_BOT}/?message=${message}&user_id=${vk_user_id}`)
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    async createPaidVkMessage(vk_user_id, performance) {

      let city = '';
      if (this.performance.performance_city_id === 1) {
        city = 'Сочи'
      } else {
        city = 'Санкт-Петербург'
      }
      let message = `Поступила оплата по выступлению учасника:
            |перенос| ${performance.user.surname} ${performance.user.name} ${performance.user.patronymic}
            |перенос| Город:  ${city}
            |перенос| ${performance.projectile.title}  
            |перенос| ${performance.solo_or_duet}
            |перенос| Спасибо!
            `
      await axios.get(`${process.env.VUE_APP_BOT}/?message=${message}&user_id=${vk_user_id}`)
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    async createVkRejectMessage(vk_user_id, performance) {

      let city = '';
      if (this.performance.performance_city_id === 1) {
        city = 'Сочи'
      } else {
        city = 'Санкт-Петербург'
      }
      let message = `❌Отклонение участия:
            |перенос| ${performance.vk_user.name}, выступление отклонено!
            |перенос| Участник:  ${performance.user.surname} ${performance.user.name} ${performance.user.patronymic}
            |перенос| Город:  ${city}
            |перенос| Снаряд: ${performance.projectile.title}         
            |перенос| Номинация: ${performance.nomination}    
            |перенос| Тип:  ${performance.solo_or_duet}
            |перенос| Стоимость:  ${performance.price}
            Для уточнения подробностей свяжитесь с организатором.`

      await axios.get(`${process.env.VUE_APP_BOT}/?message=${message}&user_id=${vk_user_id}`)
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },
    async deletePerformance(performance) {
      console.log(performance)
      const config = {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
      };

      const url = `${process.env.VUE_APP_DOMAIN}/api/performance-delete`;

      const data = {
        id: performance.id,
        vk_user_id: performance.vk_user.id
      }

      await axios.post(url, data, config)
          .then((res) => {
            console.log(res)
            // this.createVkRejectMessage(res.data.vk_id, performance)
            // this.performances()
          })
          .catch((err) => {
            console.log(err)
          })
    },
  }
}
</script>

<style>
.table-wrapper {
  position: absolute;
  text-align: center;

}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th,
td {
  border-top: 2px solid #ccc;
  border-left: 1px solid #ccc;
  white-space: nowrap;
  padding: 5px;
}

.sticky-column {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-top: 2px solid #ccc;
  position: sticky;
  left: 0;
  z-index: 6;
  background-color: #f2f2f2;
}

td {
  white-space: pre-wrap;
  text-align: center;
}

.updated_level {
  background-color: rgba(255, 0, 127, 0.75); /* Здесь 0.5 - это 50% прозрачности */
  opacity: 0.8; /* Здесь вы можете установить любое значение от 0 до 1 */
  filter: brightness(1.2); /* Здесь вы можете установить любое значение больше 1 */
  color: black;
}


</style>
<template>
    <div>
        <!-- Отображение существующих таблиц -->
        <div v-for="(table) in filteredTables" :key="table.id" class="tyming">
            <div v-if="table.type !== 'break'">
                <div class="group">
                    <h2>{{ table.projectile }} - {{ table.nomination }} - {{ table.level }}</h2>
                    <div class="remove-button">
                        <ui-button @click="removeTable(table.tableRemoveId)" raised class="delete-btn">Удалить таблицу
                        </ui-button>
                    </div>
                </div>
                <table>
                    <thead>
                    <tr>
                        <th>Время</th>
                        <th>Номер</th>
                        <th>ФИО</th>
                        <th>Город</th>
                        <th>Соло/Дуэт</th>
                        <th>Уровень</th>
                        <th>Номинация</th>
                        <th>Снаряд</th>
                        <th>Категория</th>
                        <th>Музыка</th>
                        <th>Фото</th>
                        <th>Видео</th>
                        <th>Свет</th>
                        <th>Текст ведущему</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(perf, perfIndex) in table.data" :key="perfIndex">
                        <td>{{ getTimeForTable(table, perfIndex) }}</td>
                        <td>
                            <input v-model="perf.participantNumber" min="1"/>
                        </td>
                        <td>
                            <input v-model="perf.user.surname" type="text">
                            <input v-model="perf.user.name" type="text">
                            <input v-model="perf.user.patronymic" type="text">
                            <input v-if="perf.patner_fio" v-model="perf.patner_fio" type="text">
                        </td>
                        <td>
                            <input v-model="perf.user.city" type="text">
                        </td>
                        <td>
                            {{ perf.solo_or_duet }}
                            <!--                            <select :value="perf.solo_or_duet" @input="updateSoloOrDuet(perf, $event.target.value)">-->
                            <!--                                <option disabled value="">Выберите соло/дуэт</option>-->
                            <!--                                <option value="Соло">Соло</option>-->
                            <!--                                <option value="Дуэт">Дуэт</option>-->
                            <!--                            </select>-->
                        </td>
                        <td>
                            {{ perf.level }}
                            <!--                            <select v-model="perf.level">-->
                            <!--                                <option disabled value="">Выберите уровень</option>-->
                            <!--                                <option v-for="level in levels" :key="level" :value="level">{{ level }}</option>-->
                            <!--                            </select>-->
                        </td>
                        <td>
                            {{ perf.nomination }}
                            <!--                            <select v-model="perf.nomination">-->
                            <!--                                <option disabled value="">Выберите номинацию</option>-->
                            <!--                                <option v-for="nomination in nominations" :key="nomination" :value="nomination">-->
                            <!--                                    {{ nomination }}-->
                            <!--                                </option>-->
                            <!--                            </select>-->
                        </td>
                        <td>
                            {{ perf.projectile.title }}
                            <!--                            <select v-model="perf.projectile.title">-->
                            <!--                                <option disabled value="">Выберите снаряд</option>-->
                            <!--                                <option v-for="projectile in projectiles" :key="projectile" :value="projectile">-->
                            <!--                                    {{ projectile }}-->
                            <!--                                </option>-->
                            <!--                            </select>-->
                        </td>
                        <td>
                            {{ perf.age_category }}
                            <!--                            <input v-model="perf.age_category" type="text">-->
                        </td>
                        <td v-if="perf.audio">
                            <audio controls>
                                <source :src="perf.audio.path" type="audio/mpeg">
                                <!-- Assuming the audio path is perf.audio.path -->
                            </audio>
                        </td>

                        <td>
                            <input v-model="perf.photo" type="text">
                        </td>
                        <td>
                            <input v-model="perf.video" type="text">
                        </td>
                        <td>
                            <input v-model="perf.light" type="text">
                        </td>
                        <td>
                            <text @click="editText(perf)">{{ perf.leader_text || 'нет текста' }}</text>
                            <div v-if="perf.isEditing">
                                <textarea v-model="perf.leader_text" @blur="saveText(perf)"/>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div v-else class="break">
                <span>{{ table.timeout }} {{ table.nameTimeout }}</span>
                <div class="remove-button">
                    <ui-button @click="removeBreak(table.breakId)" raised>Удалить перерыв</ui-button>
                </div>
            </div>
        </div>
    </div>

    <div class="down-header">
        <div class="top-separation">
            <div class="upload_tyming">
                <div class="save_tyming">
                    <input v-model.trim="title" placeholder="название тайминга">
                    <ui-button @click="sendData" raised>СОХРАНИТЬ ВАШ ТАЙМИНГ</ui-button>
                </div>
                <div id="upload_tyming">
                    <select v-model="selectedOption">
                        <option v-for="(option, id) in tymingOptions" :key="id">
                            {{ option }}
                        </option>
                    </select>
                    <ui-button @click="uploadTiming" raised>Выгрузить тайминг</ui-button>
                </div>
                <div class="upload_excel">
                    <select v-model="selectedOption">
                        <option v-for="(option, id) in tymingOptions" :key="id">
                            {{ option }}
                        </option>
                    </select>
                    <ui-button @click="downloadExcelFromFrontendArray(tables)" raised>скачать в excel</ui-button>
                </div>
            </div>
        </div>

        <div style="display: block">
            <ui-button @click="toggleFilter()">
                <span v-if="filterCity === 2">Выберите город</span>
                <span v-else-if="filterCity === true">Сочи</span>
                <span v-else>Питер</span>
            </ui-button>
        </div>

        <div class="select-container">
            <select v-model="newTableLevel">
                <option v-for="level in levels" :key="level" :value="level">{{ level }}</option>
            </select>
        </div>

        <div class="select-container">
            <select v-model="newTableNomination">
                <option v-for="nomination in nominations" :key="nomination" :value="nomination">{{
                    nomination
                    }}
                </option>
            </select>
        </div>

        <div class="select-container">
            <select v-model="newTableProjectile">
                <option v-for="projectile in projectiles" :key="projectile" :value="projectile">{{
                    projectile
                    }}
                </option>
            </select>
        </div>

        <div class="create-table">
            <vue-timepicker
                    v-model.trim="time"
                    format="HH:mm"
                    placeholder="время номера"
                    style="margin-bottom: 6px;"
            ></vue-timepicker>
            <ui-button @click="addTable(newTableLevel, newTableNomination, newTableProjectile, tableRemoveId)" raised>
                Добавить таблицу
            </ui-button>
        </div>

        <div style="padding: 10px;">
            <input
                    v-model.trim="breakTime"
                    placeholder="время в минутах"
                    style="padding: 10px"
            />
            <input type="text" placeholder="перерыв/награждение" v-model="timeout" style="margin-left: 20px">
            <ui-button @click="addBreak(breakId)" raised style="padding: 15px; margin-left: 10px;">Добавить</ui-button>
        </div>

        <div style="padding: 10px">
            <a href="https://appdev.inform-s.ru/storage/tyming.zip" v-if="audioIsLoad"><ui-button @click="audioLoad" raised>Скачать архив</ui-button></a>
            <ui-button v-else @click="getAudioZip">Загрузить архив музыки</ui-button>
        </div>
    </div>
  <pre>
      {{ tables }}
  </pre>
</template>
<script>
import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'
import axios from "axios";
import * as XLSX from 'xlsx';

const levels = [
    'Дебют',
    'Начинающие',
    'Продвинутые',
    'Профессионалы',
    'Элита',
];

const projectiles = [
    'Пилон',
    'Полотно',
    'Кольцо',
    'Стропы',
    'Петля (гамак)',
    'Оригинальный снаряд',
    'Бортик',
];

const nominations = ['Арт', 'Спорт', 'Exotic pole'];
export default {
    components: {
        VueTimepicker
    },
    props: ['id'],
    data() {
        return {
            nominations,
            projectiles,
            levels,
            tables: [],
            timeout: '',
            time: '',
            breakTime: '',
            data: [],
            performanceArr: [],
            selectedLevel: null,
            selectedNomination: null,
            selectedProjectile: null,
            newTableLevel: 'Начинающие',
            newTableNomination: 'Спорт',
            newTableProjectile: 'Полотно',
            currentParticipantNumber: 1,
            lastTime: '10:00',
            tableId: 0,
            breakId: 0,
            tableRemoveId: 0,
            isUpdated: false,
            title: '',
            isEditing: false,
            tymingOptions: [],
            selectedOption: '',
            filterCity: 2,
            excelData: [],
            participantNumberMap: {},
            audioIsLoad: false,
        };
    },
    created() {
        this.performances();
        this.givTyming();
    },
    computed: {
        filteredTables() {
            const currentId = this.$route.params.id;
            return this.tables.filter(table => table.id === currentId);
        },
    },
    methods: {
        async downloadExcelFromFrontendArray(tables) {
            try {
                const formattedTables = [];

                tables.forEach(table => {
                    if (table.data) {
                        const startTimeParts = table.startTime.split(':');
                        let currentTime = new Date();
                        currentTime.setHours(startTimeParts[0], startTimeParts[1], 0, 0);

                        formattedTables.push({
                            'Время': '',
                            'перерыв': '',
                            'Номер': '',
                            'ФИО участника': '',
                            'Город': '',
                            'Соло/Дуэт': '',
                            'Уровень подготовки': table.level,
                            'Номинация': table.nomination,
                            'Снаряд': table.projectile,
                            'Категория': '',
                            'Музыка': '',
                            'Фото': '',
                            'Видео': '',
                            'Свет': '',
                            'Комментарий': '',
                        });

                        table.data.forEach(item => {
                            formattedTables.push({
                                'Время': currentTime.toLocaleTimeString('ru-RU', {hour: '2-digit', minute: '2-digit'}),
                                'перерыв': '',
                                'Номер': item.participantNumber,
                                'ФИО участника': item.user ? `${item.user.surname} ${item.user.name} ${item.user.patronymic}` : '',
                                'Город': item.user ? item.user.city : '',
                                'Соло/Дуэт': item.solo_or_duet,
                                'Уровень подготовки': item.level,
                                'Номинация': item.nomination,
                                'Снаряд': item.projectile.title,
                                'Категория': item.age_category,
                                'Музыка': item.audio.path,
                                'Фото': item.photo,
                                'Видео': item.video,
                                'Свет': item.light,
                                'Комментарий': item.leader_text,
                            });

                            currentTime = new Date(currentTime.getTime() + 5 * 60 * 1000);
                        });
                    } else {
                        formattedTables.push({
                            'Время': '',
                            'перерыв': `${table.timeout} ${table.nameTimeout}`,
                            'Номер': '',
                            'ФИО участника': '',
                            'Город': '',
                            'Соло/Дуэт': '',
                            'Уровень подготовки': '',
                            'Номинация': '',
                            'Снаряд': '',
                            'Категория': '',
                            'Музыка': '',
                            'Фото': '',
                            'Видео': '',
                            'Свет': '',
                            'Комментарий': '',
                        });
                    }
                });

                const worksheet = XLSX.utils.json_to_sheet(formattedTables);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

                const excelBuffer = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
                const dataBlob = new Blob([excelBuffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

                const url = window.URL.createObjectURL(dataBlob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'tyming.xlsx');
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading data:', error);
            }
        },

        // запросы к API
        async audioLoad() {
            this.audioIsLoad = !this.audioIsLoad;
        },

        getAudioZip() {
            this.$alert('кнопка поменяет цвет когда можно будет скачать архив')
            let ids = this.tables.flatMap(table => table.id);

            axios.post(`${process.env.VUE_APP_DOMAIN}/api/audio-tyming`, {
                performance_id: ids.flat(),
            }).then((r) => {
                if (r.data.message === 'success') {
                    this.audioIsLoad = true
                }
                console.log(r)
            }).catch((e) => {
                console.log(e)
            })
        },

        uploadTiming() {
            axios.post(`${process.env.VUE_APP_DOMAIN}/api/upload-data`, {
                selectedOption: this.selectedOption,
                page: this.$route.params.id,
            }).then((response) => {
                if (response.data && Array.isArray(response.data)) {
                    let maxParticipantNumber = 0;
                    response.data.forEach(table => {
                        if (table.data && Array.isArray(table.data)) {
                            table.data.forEach(performance => {
                                maxParticipantNumber = Math.max(maxParticipantNumber, performance.participantNumber);
                                maxParticipantNumber++;
                            });
                        }
                    });
                    this.currentParticipantNumber = maxParticipantNumber;
                    this.tables = response.data;
                } else {
                    this.tables = []; // or handle the situation appropriately
                }
            }).catch((error) => {
                console.error('Ошибка при загрузке данных:', error);
            });
        },

        async givTyming() {
            try {
                const response = await axios.post(`${process.env.VUE_APP_DOMAIN}/api/give-data`);

                this.tymingOptions = Object.values(response.data);
            } catch (error) {
                console.error(error);
            }
        },

        sendData() {
            if (this.title.trim() === '') {
                this.$alert('Пожалуйста, введите название тайминга');
                return;
            }
            if (Array.isArray(this.tymingOptions) && this.tymingOptions.some(option => option === this.title)) {
                this.$alert('Название тайминга не должно совпадать с существующими вариантами.');
                return;
            }

            let currentParticipantNumber = 1;

            const dataToSend = this.tables.map((table, index) => {
                const tableData = {
                    title: this.title,
                    number: currentParticipantNumber,
                    order: index,
                    record_type: table.type || 'table',
                    ...table
                };
                currentParticipantNumber++;
                return tableData;
            });

            axios.post(`${process.env.VUE_APP_DOMAIN}/api/save-data`, {
                data: dataToSend
            })
                .then((response) => {
                    console.log(response.data);
                    this.$alert('Тайминг сохранен');
                    this.givTyming();
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async performances() {
            const url = `${process.env.VUE_APP_DOMAIN}/api/data-tyming`;

            this.performanceArr = this.performanceUpdated
            await axios.get(url)
                .then((res) => {
                    console.log(res);
                    this.data = res.data;
                    this.performanceArr = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        //фильтрация по городам
        toggleFilter() {
            if (this.filterCity === true) {
                this.filterCity = false;
            } else {
                this.filterCity = true;
            }
            this.filterData();
        },

        filterData() {
            if (this.filterCity === true) {
                this.data = this.performanceArr.filter(item => item.performance_city_id === 1);
            } else if (this.filterCity === false) {
                this.data = this.performanceArr.filter(item => item.performance_city_id === 2);
            } else {
                this.data = this.performanceArr;
            }
        },

        // Работа с таблицей
        editText(perf) {
            perf.isEditing = !perf.isEditing;
        },

        saveText(perf) {
            perf.isEditing = false;
        },

        removeBreak(breakId) {
            // Находим индекс таблицы, у которой breakId совпадает с переданным значением
            const index = this.tables.findIndex(table => table.breakId === breakId);
            if (index !== -1) {
                // Удаляем таблицу из массива по найденному индексу
                this.tables.splice(index, 1);
                // Перезаписываем participantNumber для оставшихся таблиц и перерывов
                this.updateParticipantNumbers();
            }
        },

        addBreak(breakId) {
            if (this.breakTime.trim() === '') {
                this.$alert('Пожалуйста, добавьте время перерыва');
                return;
            }
            let id = this.$route.params.id;
            this.tables.push({
                id: id,
                type: 'break',
                timeout: this.breakTime,
                breakId: breakId,
                nameTimeout: this.timeout,
                order: this.breakId
            });
            this.breakId++;
        },

        addTable(level, nomination, projectile, tableRemoveId) {
            if (this.time.trim() === '') {
                this.$alert('Пожалуйста, добавьте время выступления');
                return;
            }
            const filteredData = this.filterAndSortData(level, nomination, projectile);

            if (filteredData.length === 0) {
                this.alertNoPerformances();
                return;
            }
            const startTime = this.time;

            let id = this.$route.params.id;

            // Устанавливаем participantNumber для каждого участника в filteredData
            let participantNumber = this.currentParticipantNumber;
            filteredData.forEach(data => {
                data.participantNumber = participantNumber++;
            });

            this.tables.push({
                id: id,
                level,
                nomination,
                projectile,
                data: filteredData,
                startTime: startTime,
                tableRemoveId: tableRemoveId
            });
            this.tableRemoveId++;

            // Обновляем currentParticipantNumber, увеличивая его на количество участников в добавленной таблице
            this.currentParticipantNumber = participantNumber;

            // Возвращаем текущее значение currentParticipantNumber
            return this.currentParticipantNumber;
        },

        removeTable(tableRemoveId) {
            // Find the index of the table where tableRemoveId matches the passed tableRemoveId
            const index = this.tables.findIndex(table => table.tableRemoveId === tableRemoveId);
            if (index !== -1) {
                // Remove the table from the array at the found index
                const removedTable = this.tables.splice(index, 1)[0];

                // Decrease currentParticipantNumber by the number of participants in the removed table
                this.currentParticipantNumber -= removedTable.data.length;

                // Rewrite participantNumber for the remaining tables and breaks
                this.updateParticipantNumbers();
            }
        },

        updateParticipantNumbers() {
            let participantNumber = 1; // Start with 1 for the first participant
            this.tables.forEach(table => {
                if (table.type === 'break') {
                    // If it's a break, we don't change the participantNumber
                    // since breaks do not have participants
                } else {
                    // If it's a table, we update the participantNumber for the participants in that table
                    table.data.forEach(data => {
                        data.participantNumber = participantNumber++;
                    });
                }
            });
            // Update currentParticipantNumber to the next available participant number
            this.currentParticipantNumber = participantNumber;
        },

        getParticipantsCountBeforeBreak(breakId) {
            // Находим индекс перерыва
            const breakIndex = this.tables.findIndex(table => table.breakId === breakId);
            if (breakIndex === -1) {
                return 0;
            }
            // Находим индекс таблицы перед перерывом
            let tableIndex = breakIndex - 1;
            while (tableIndex >= 0 && this.tables[tableIndex].type === 'break') {
                tableIndex--;
            }
            // Если перед перерывом нет таблицы, возвращаем 0
            if (tableIndex < 0) {
                return 0;
            }
            // Возвращаем количество участников в таблице перед перерывом
            return this.tables[tableIndex].data.length;
        },
        filterAndSortData(level, nomination, projectile) {
            return this.data
                .filter(item => item.level === level && item.nomination === nomination && item.projectile.title === projectile)
                .sort(this.sortData)
                .map(this.mapDataWithParticipantNumber);
        },

        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        extractAgeCategory(ageCategory) {
            const match = ageCategory.match(/(\d+)-(\d+)|18+/);
            if (match) {
                if (match[1]) {
                    return {
                        start: parseInt(match[1], 10),
                        end: parseInt(match[2], 10)
                    };
                } else {
                    return {
                        start: 18,
                        end: Infinity
                    };
                }
            }
            return null;
        },

        sortData(a, b) {
            // Сначала сравниваем соло/дуэт
            

            const aAgeCategory = this.extractAgeCategory(a.age_category);
            const bAgeCategory = this.extractAgeCategory(b.age_category);

            // Сравниваем начальные значения категорий возраста
            if (aAgeCategory && bAgeCategory) {
                // Сравниваем начальные значения категорий возраста
                if (aAgeCategory.start < bAgeCategory.start) return -1;
                if (aAgeCategory.start > bAgeCategory.start) return 1;

                // Если начальные значения одинаковы, сравниваем конечные значения категорий возраста
                if (aAgeCategory.end < bAgeCategory.end) return -1;
                if (aAgeCategory.end > bAgeCategory.end) return 1;
            }

            // Если категории возраста не соответствуют шаблону, сравниваем их как строки
            if (a.age_category < b.age_category) return 1;
            if (a.age_category > b.age_category) return -1;

            if (a.solo_or_duet > b.solo_or_duet) return -1;
            return 0;
        },


        mapDataWithParticipantNumber(item, index) {
            return {
                ...item,
                participantNumber: this.currentParticipantNumber + index
            };
        },

        alertNoPerformances() {
            this.$alert('нет выступлений с такими данными');
        },

        updateSoloOrDuet(performance, soloOrDuet) {
            // Преобразование входных данных в нижний регистр
            performance.solo_or_duet = soloOrDuet.toUpperCase();
            // Установка флага обновления
            performance.isUpdated = true;
        },

        getTimeForTable(table, perfIndex) {
            // Проверяем, что таблица и startTime существуют и являются корректными
            if (!table || !table.startTime || typeof table.startTime !== 'string' || !/^([01][0-9]|2[0-3]):[0-5][0-9]$/.test(table.startTime)) {
                console.error('Invalid table or startTime:', table);
                return '00:00';
            }

            const interval = 5; // Интервал в минутах
            const [hours, minutes] = table.startTime.split(':').map(Number);

            // Вычисляем новое время на основе индекса выступления и начального времени таблицы
            const newMinutes = (minutes + interval * perfIndex) % 60;
            const newHours = (hours + Math.floor((minutes + interval * perfIndex) / 60)) % 24;

            // Форматируем время и возвращаем его
            return `${newHours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`;
        },

    }
};
</script>

<style>
table {
    margin: 10px 0 10px 0;
    width: 100%;
}

th, tr, td {
    border-bottom: 2px solid black;
}

button {
    margin-right: 10px;
    margin-left: 5px;
}

th p {
    text-decoration: underline 1px;
    margin-top: 15px;
}

.break {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    width: 100%;
    height: 60px;
    font-size: 25px;
    background-color: rgba(60, 179, 113, 0.8);
    color: #ffffff;
    margin: 5px;
    text-align: center;
    padding: 10px;
}

.remove-button {
    margin-left: 10px; /* Добавляем немного отступа слева для кнопки */
}

.down-header {
    border-radius: 5px;
    background-color: rgb(218, 217, 217);
    margin-bottom: 20px;
    width: 100%;
}

select {
    appearance: none; /* Убираем дефолтный внешний вид */
    background-color: #f8f8f8; /* Фоновый цвет */
    border: 1px solid #ddd; /* Рамка */
    border-radius: 4px; /* Скругление углов */
    color: rgba(51, 51, 51, 0.9); /* Цвет текста */
    cursor: pointer; /* Курсор при наведении */
    font-size: 14px; /* Размер текста */
    margin: 10px; /* Убираем внешние отступы */
    padding: 10px; /* Внутренние отступы */
    width: 200px;
    transition: border-color 0.3s ease; /* Плавное изменение цвета рамки */
    height: 39px;
}

/* Стили для селекта при фокусе */
select:focus {
    border-color: #aaa; /* Изменение цвета рамки при фокусе */
    outline: none; /* Убираем обводку при фокусе */
}

/* Стили для селекта при наведении */
select:hover {
    border-color: #aaa; /* Изменение цвета рамки при наведении */
}

/* Стили для селекта при активном состоянии (когда открыт список) */
select:active {
    border-color: #aaa; /* Изменение цвета рамки при активном состоянии */
}

/* Стили для селекта в disabled состоянии */
select:disabled {
    background-color: #eee; /* Фоновый цвет при disabled состоянии */
    cursor: not-allowed; /* Курсор недоступности */
    opacity: 0.6; /* Полупрозрачность */
}

/* Стили для селекта в option */
select option {
    font-size: 18px; /* Размер текста в option */
    padding: 10px; /* Внутренние отступы в option */

}

/* Стили для label */
label {
    font-size: 24px; /* Размер текста */
    margin-left: 10px; /* Отступ снизу */
    color: #333; /* Цвет текста */
}

.select-container {
    display: inline-block;
    margin-right: -6px;
}


.tyming h2 {
    text-align: center;
}

.group {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Выравнивание по вертикали */
    width: 100%;
    height: 50px;
    background-color: #f8cc57;
    border-radius: 5px;
    padding: 0 10px; /* Добавляем отступы для содержимого */
}

.delete-btn {
    margin-left: 10px; /* Добавляем немного отступа слева для кнопки */
}

input {
    width: 200px;
    height: 39px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease-in-out;
    color: rgba(0, 0, 0, 0.8);
    box-sizing: border-box; /* Этот свойство включает padding и border в ширину и высоту элемента */
}

input:focus {
    border-color: #5a6268;
    outline: none;
}

.top-separation {

    right: 20px;
    margin-top: 10px;
    width: max-content;
    border-radius: 5px;
}

.create-table {
    display: inline-block;
    padding: 10px;
}

.create-table button {
    margin-bottom: 4px;
    margin-left: 10px;
}


.upload_tyming {
    display: inline-block;
    width: max-content;
    border-radius: 5px;
    top: 345%;
    right: 0;
}

.upload_excel {
    display: block;
}

.upload_excel button {
    margin-bottom: 4px;
}

.save_tyming {
    display: block;
    padding: 10px;
}

.save_tyming button {
    margin-left: 15px;
}

#upload_tyming button {
    margin-bottom: 4px;
}

</style>